import { AVAILABLE_LOCALES } from '../api/constants'
import {
    WEBSHOP_REQUEST,
    WEBSHOP_SUCCESS,
    WEBSHOP_FAILED
} from '../actions/webshop-actions'

export default function webshop(
    state = {
        isFetching: false,
        availableLocales: AVAILABLE_LOCALES,
        organizer: {},
        menu: [],
        page: [],
        products: [],
        designs: [],
        creditCards: [],
        fees: {
            shipping: 0,
            handling: 0
        },
        error: null
    }, action) {
    switch (action.type) {
        case WEBSHOP_REQUEST:
            return {
                ...state,
                isFetching: true,
                lastRequested: action.requestedAt,
                error: null
            }
        case WEBSHOP_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lastSucceeded: action.receivedAt,
                organizer: action.organizer,
                availableLocales: action.availableLocales,
                menu: action.menu,
                pages: action.pages,
                products: action.products,
                designs: action.designs,
                creditCards: action.creditCards,
                fees: action.fees,
                logo: action.logo,
                receiptHtml: action.receiptHtml,
                siteType: action.siteType,
                salesChannel: action.salesChannel
            }
        case WEBSHOP_FAILED:
            return {
                ...state,
                isFetching: false,
                lastFailed: action.receivedAt,
                error: action.error
            }
        default:
            return state
    }
}