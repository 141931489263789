import _ from 'lodash'
import { AVAILABLE_LOCALES } from '../api/constants'
import api from '../api/webshop-api'

export const WEBSHOP_REQUEST = 'WEBSHOP_REQUEST'
export const WEBSHOP_SUCCESS = 'WEBSHOP_SUCCESS'
export const WEBSHOP_FAILED = 'WEBSHOP_FAILED'

export default function getWebshop(organizerId, siteType, salesChannel) {
    return dispatch => {
        dispatch(webshopRequest());
        return api.fetchWebshop(organizerId, siteType, salesChannel)
            .then(webshop => dispatch(webshopSuccess({ ...webshop, siteType, salesChannel })), error => dispatch(webshopFailed()));
    }
}

const webshopRequest = () => {
    return {
        type: WEBSHOP_REQUEST,
        requestedAt: Date.now()
    }
}

const webshopSuccess = (json) => {
    return {
        type: WEBSHOP_SUCCESS,
        succeededAt: Date.now(),
        organizer: json.organizer,
        availableLocales: extractLocales(json.organizer.countries),
        menu: json.menu,
        pages: json.pages,
        products: json.products,
        designs: json.designs,
        creditCards: json.creditCards,
        fees: json.organizer.fees,
        logo: json.organizer.logo,
        receiptHtml: json.organizer.receiptHtml,
        siteType: json.siteType,
        salesChannel: json.salesChannel
    }
}

const webshopFailed = () => {
    return {
        type: WEBSHOP_FAILED,
        failedAt: Date.now(),
        error: 'Der var ingen data at hente for denne webshop'
    }
}

function extractLocales(languages) {
    const ids = _.split(languages, (','));
    return _.map(ids, (id) => {
        const match = _.find(AVAILABLE_LOCALES, (locale) => { return locale.id == id });
        if (match) return match;
    });
}